// eslint-disable-next-line no-unused-vars
import { get, post, update, del, getExl, exportExl } from '@/utils/axios'
export default {
  // 获取登录验证码
  getCode() {
    return get('/ht/adminUser/captchaImage')
  },
  // 用户登录
  userLogin(p) {
    return post('/ht/adminUser/login', p)
  },
  // 居民管理模块
  // 获取社区管理列表
  getCommunitySystemUser(data, page) {
    return post(
      `/ht/community/list?pageNum=${page.pageNum}&pageSize=${page.pageSize}`,
      data
    )
  },
  // 根据社区id获取社区详情
  getCommuintyById(id) {
    return get('/ht/community/id?id=' + id)
  },
  // 新增社区
  addCommunity(p) {
    return post('/ht/community/add', p)
  },
  // 修改社区
  updateCommunity(p) {
    return post('/ht/community/edit', p)
  },
  // 删除社区
  delCommunity(ids) {
    return post('/ht/community/del', ids)
  },

  // 获取小组列表
  getGroupListes(data, page) {
    return post(
      `/ht/group/list?pageNum=${page.pageNum}&pageSize=${page.pageSize}`,
      data
    )
  },
  // 根据小组id查询详情
  getGroupInfoById(id) {
    return get('/ht/group/id?id=' + id)
  },
  // 新增小组
  groupAdd(p) {
    return post('/ht/group/add', p)
  },
  // 编辑小组
  groupUpdate(p) {
    return post('/ht/group/edit', p)
  },
  // 根据小组ID,删除小组
  delGroup(ids) {
    return post('/ht/group/del', ids)
  },
  // 获取居民管理列表
  getResidentList(data, page) {
    return post(
      `/ht/info/list?pageNum=${page.pageNum}&pageSize=${page.pageSize}`,
      data
    )
  },
  // 新增居民
  residentAdd(p) {
    return post('/ht/info/add', p)
  },
  // 修改居民
  residentUpdate(p) {
    return post('/ht/info/edit', p)
  },
  // 根据id查询居民的详细信息
  getResidentInfo(id) {
    return get('/ht/info/id?id=' + id)
  },
  delResident(ids) {
    return post('/ht/info/ids', ids)
  },
  // 将居民加入到小组
  intoGroup(data) {
    return post('/ht/group/insertGroup', data)
  },
  // 设置组长或副组长
  setGroupRole(data) {
    return post('/ht/info/updateGroupRole', data)
  },

  // 查询打卡任务
  getTaskList(data, page) {
    return post(
      `/ht/punch/list?pageNum=${page.pageNum}&pageSize=${page.pageSize}`,
      data
    )
  },
  // 新增打卡任务
  taskAdd(data) {
    return post('/ht/punch/add', data)
  },
  // 修改打卡任务
  taskEdit(data) {
    return post('/ht/punch/edit', data)
  },
  // 发布打卡任务
  taskStatusChange(ids, methodsType) {
    return post(`/ht/punch/${methodsType}`, ids)
  },
  // 根据任务id查询任务详情
  getTaskInfoById(id) {
    return get(`/ht/punch/details/${id}`)
  },
  // 个人排名
  personalRanking(data, page) {
    return post(
      `/ht/ranking/personalRanking?pageNum=${page.pageNum}&pageSize=${page.pageSize}`,
      data
    )
  },
  exportPerRank(data) {
    return exportExl('/ht/ranking/personalRankingExport', data)
  },
  // 小组排名
  groupRanking(data, page) {
    return post(
      `/ht/ranking/groupRanking?pageNum=${page.pageNum}&pageSize=${page.pageSize}`,
      data
    )
  },
  // 导出小组排名
  exportGroupRank(data) {
    return exportExl('/ht/ranking/groupRankingExport', data)
  },
  // 获取小组本期总结
  groupSumGet(data) {
    return post('/ht/ranking/summarize', data)
  },
  // 根据小组id，查看得分列表
  groupTaskGet(page) {
    return get(
      `/ht/ranking/groupid?pageSize=${page.pageSize}&pageNum=${page.pageNum}&id=${page.id}`
    )
  },
  // 根据居民id查询打卡详情
  personTaskGet(page) {
    return get(
      `/ht/ranking/userid?pageSize=${page.pageSize}&pageNum=${page.pageNum}&id=${page.id}`
    )
  },
  // 导出小组打卡列表
  exportGroupTask(id) {
    return getExl(`/ht/ranking/exportgroup?id=${id}`)
  },
  // 导出个人打卡列表
  exportPersonTask(id) {
    return getExl(`/ht/ranking/exportuser?id=${id}`)
  },
  // 查看打卡问题详情
  getQuestion(ids) {
    return post('/ht/ranking/quesId', ids)
  },

  /* 试题管理 */
  // 试题标签管理
  getExameTitileType(data, page) {
    return post(
      `/ht/management/question/label/list?pageSize=${page.pageSize}&pageNum=${page.pageNum}`,
      data
    )
  },
  // 新增标签
  labelAdd(data) {
    return post('/ht/management/question/label/save', data)
  },
  // 修改标签
  labelUpdate(data) {
    return post('/ht/management/question/label/update', data)
  },
  // 删除
  labelDel(ids) {
    return post('/ht/management/question/label/delete', ids)
  },
  // 试题列表查询
  getTestqList(data, page) {
    return post(
      `/ht/management/question/list?pageSize=${page.pageSize}&pageNum=${page.pageNum}`,
      data
    )
  },
  // 新增试题
  testqAdd(data) {
    return post('/ht/management/question/save', data)
  },
  // 修改试题
  testqUpdate(data) {
    return post('/ht/management/question/update', data)
  },
  // 删除试题
  testqDel(ids) {
    return post('/ht/management/question/delete', ids)
  },
  // 根据试题id查看试题详情
  testQinfoGet(id) {
    return get(`/ht/management/question/getDetail/${id}`)
  },
  // 试卷管理
  // 获取试卷列表
  getExamList(data, page) {
    return post(
      `/ht/management/paper/list?pageSize=${page.pageSize}&pageNum=${page.pageNum}`,
      data
    )
  },

  // 新增试卷
  paperAdd(data) {
    return post('/ht/management/paper/save', data)
  },
  paperUpdate(data) {
    return post('/ht/management/paper/update', data)
  },
  // 试卷预览
  paperPreview(ids) {
    return post('/ht/management/paper/preview', ids)
  },

  // 试卷发布
  paperPublish(ids) {
    return post('/ht/management/paper/release', ids)
  },
  // 单个或批量删除
  paperListDel(ids) {
    return post('/ht/management/paper/delete', ids)
  },
  // 根据id获取试卷详情
  paperInfoGet(id) {
    return get(`/ht/management/paper/getDetail/${id}`)
  },

  // 获取推送用户列表
  getPushUser(data, page) {
    return post(
      `/ht/management/plan/pushUsers?pageSize=${page.pageSize}&pageNum=${page.pageNum}`,
      data
    )
  },
  // 获取推送医生列表
  getPushDoctors(data, page) {
    return post(
      `/ht/management/plan/pushDoctors?pageSize=${page.pageSize}&pageNum=${page.pageNum}`,
      data
    )
  },

  // 考试任务下发
  // 获取考试任务列表
  getExamTaskList(data, page) {
    return post(
      `/ht/management/plan/list?pageSize=${page.pageSize}&pageNum=${page.pageNum}`,
      data
    )
  },

  getExamTaskListDoctor(data, page) {
    return post(
      `/ht/management/plan/listDoctor?pageSize=${page.pageSize}&pageNum=${page.pageNum}`,
      data
    )
  },
  // 新增考试任务
  examTaskAdd(data) {
    return post('/ht/management/plan/save', data)
  },
  // 修改考试任务
  examTaskUpdate(data) {
    return post('/ht/management/plan/update', data)
  },
  // 根据任务id查询已配置的用户
  examTaskUser(planId) {
    return post(`/ht/management/plan/endPushUsers/${planId}`)
  },
  // 根据任务id查询已配置的医生
  examTaskDoctors(planId) {
    return post(`/ht/management/plan/endPushDoctors/${planId}`)
  },
  // 删除考试计划
  examTaskDel(ids) {
    return post('/ht/management/plan/delete', ids)
  },
  // 考试任务分析
  getExamTaskStaticList(data, page) {
    return post(
      `/ht/management/planAnalyse/list?pageSize=${page.pageSize}&pageNum=${page.pageNum}`,
      data
    )
  },
  // 考试任务分析统计
  // 获取打卡任务详情
  getTaskInfoList(data, page) {
    return post(
      `/ht/management/planAnalyse/planList?pageSize=${page.pageSize}&pageNum=${page.pageNum}`,
      data
    )
  },
  // 获取用户考试详情
  userTaskInfoGet(paperId, planId, userId) {
    return get(
      `ht/wechat/examination/getPaperDetail?paperId=${paperId}&planId=${planId}&userId=${userId}`
    )
  },

  // 积分商城--商品分类列表查询
  getGoodsTypeList(data, page) {
    return post(
      `/ht/management/productType/list?pageSize=${page.pageSize}&pageNum=${page.pageNum}`,
      data
    )
  },
  // 新增商品分类
  goodsTypeAdd(p) {
    return post('/ht/management/productType/save', p)
  },
  // 修改商品分类
  goodsTypeUpdate(data) {
    return post('/ht/management/productType/update', data)
  },
  // 模糊商品分类查询
  getProductType(data) {
    return get('/ht/management/productType/findType', data)
  },
  // 批量删除(商品分类)
  goodsTypeDel(ids) {
    return post('/ht/management/productType/delete', ids)
  },
  // 获取商品分类详情
  getGoodsTypeDetail(id) {
    return get('/ht/management/productType/detail/{id}', id)
  },
  // 商品管理列表查询
  getGoodsManageList(data, page) {
    return post(
      `/ht/management/product/list?pageSize=${page.pageSize}&pageNum=${page.pageNum}`,
      data
    )
  },
  // 新增商品(商品管理)
  goodsManageAdd(data) {
    return post('/ht/management/product/save', data)
  },
  // 修改商品(商品管理)
  goodsManageUpdate(data) {
    return post('/ht/management/product/update', data)
  },
  // 获取商品详情
  getProductInfo(id) {
    return get(`/ht/management/product/detail/${id}`)
  },
  // 批量删除(商品管理)
  goodsManageDel(ids) {
    return post('/ht/management/product/delete', ids)
  },
  // 批量修改上架状态
  batchShelvesProductStatus(ids, status) {
    return post(`/ht/management/product/putaway/${status}`, ids)
  },
  // 兑换记录查询列表
  getExchangeRecordList(data, page) {
    return post(
      `/ht/management/exchangeRecord/list?pageSize=${page.pageSize}&pageNum=${page.pageNum}`,
      data
    )
  },
  // 根据兑换码查询兑换记录
  getExchangeCode(exchangeCode, data) {
    return post(`/ht/wechat/exchangeCommodity/writeOff/${exchangeCode}`, data)
  },

  // 阿里云图片上传授权
  setOss() {
    return get('/ht/oss/policy')
  },

  // 菜单管理列表
  getMenuList(data) {
    return post(
      `/ht/menu/list?pageSize=${data.pageSize}&pageNum=${data.pageNum}`,
      data
    )
  },
  getMenuOptions(data) {
    return post('/ht/menu/list', data)
  },
  // 新增菜单
  addMenu(data) {
    return post('/ht/menu/add', data)
  },
  // 修改菜单
  editMenu(data) {
    return post('/ht/menu/edit', data)
  },
  // 删除菜单
  delMenuByMenuId(data) {
    return get('/ht/menu/remove', data)
  },

  // 角色管理列表
  getRoleList(data) {
    return post(
      `/ht/role/list?pageSize=${data.pageSize}&pageNum=${data.pageNum}`,
      data
    )
  },
  // 新增角色
  addRole(data) {
    return post('/ht/role/add', data)
  },
  // 修改角色
  editRole(data) {
    return post('/ht/role/edit', data)
  },
  // 删除角色
  delRole(data) {
    return post('/ht/role/re', data)
  },
  // 查询所有角色菜单列表
  getAllMenu(data) {
    return get('/ht/role/authUser/allmenu', data)
  },
  // 加载对应角色菜单列表树
  getMenuTreeById(data) {
    return get('/ht/menu/roleId', data)
  },

  // 系统管理模块
  getUserInfo() {
    return get('/ht/adminUser/getInfo')
  },
  // 获取系统管理用户列表
  getSystemUser(data, page) {
    return post(
      `/ht/adminUser/list?pageSize=${page.pageSize}&pageNum=${page.pageNum}`,
      data
    )
  },
  // 添加后台账户
  addSysUser(p) {
    return post('/ht/adminUser/add', p)
  },
  // 修改后台账户
  updateSysUser(p) {
    return post('/ht/adminUser/updateadminUser', p)
  },
  // 根据当前用户获取对应的菜单
  getMenu(p) {
    return get('/ht/menu/tree', p)
  },
  // 根据区域ids数组查询社区列表
  getComityList(data) {
    return post('/ht/permission/selectinfo', data)
  },
  // 新增用户数据权限
  userPowerAdd(data) {
    return post('/ht/permission/add', data)
  },
  // 新增用户数据权限
  userPowerUpdate(data) {
    return post('/ht/permission/edit', data)
  },
  // 授权回显
  getUserPower(userId) {
    return get('/ht/permission/dataInfo?userId=' + userId)
  },
  // // 授权回显
  // getUserInfoPower(userId) {
  //   return get('/ht/permission/dataInfo?userId=' + userId)
  // },

  // 获取当前用户所拥有的地区信息
  getUserArea() {
    return get('/ht/permission/selectArea')
  },
  // 身份证模板导出
  idNumberDlownd() {
    return exportExl('/ht/info/importCardTemplate')
  },
  // 居民身份证号导入
  importIdNumber(data) {
    return post('/ht/info/importCard', data)
  },

  // 处方列表查询
  getRecipelList(data, page) {
    return post(
      `/ht/prescription/list?pageSize=${page.pageSize}&pageNum=${page.pageNum}`,
      data
    )
  },

  taskRecordExport(data) {
    return exportExl('/ht/punch/export', data)
  },

  // 积分明细列表/ht/details/list
  getScoreDetails(data, page) {
    return post(
      `/ht/details/list?pageSize=${page.pageSize}&pageNum=${page.pageNum}`,
      data
    )
  },
  // 新增积分
  addScore(data) {
    return post('/ht/details', data)
  },
  // 删除积分明细
  delScore(ids) {
    return del('/ht/details/del/' + ids)
  },
  // 导出积分明细/ht/details/export
  userScoreExport(data) {
    return exportExl('/ht/details/export', data)
  },
  // 查看积分详情
  getTaskDetails(id) {
    return get(`/ht/details/${id}`)
  },
  // 获取近7天得体重
  getSevenDayWeight(data) {
    return post('/ht/weight/getWeights', data)
  },
  // 获取近7天得血糖
  getSevenDayBloodSuger(data) {
    return post('/ht/bloodglucose/getBloodGlucoses', data)
  },
  // 根据社区id获取小组列表
  getGroupListByCompId(communityId) {
    return get(`/ht/group/getListByCommunityId?communityId=${communityId}`)
  },

  // 健康数据
  // 1、体重
  getWeightList(data, page) {
    return post(
      `/ht/weight/query?pageSize=${page.pageSize}&pageNum=${page.pageNum}`,
      data
    )
  },
  // 2、血压
  getBloodList(data, page) {
    return post(
      `/ht/bloodpressure/query?pageSize=${page.pageSize}&pageNum=${page.pageNum}`,
      data
    )
  },
  // 3、血脂
  getBloodFatList(data, page) {
    return post(
      `/ht/bloodfat/query?pageSize=${page.pageSize}&pageNum=${page.pageNum}`,
      data
    )
  },
  // 4、血糖
  getBloodSugerList(data, page) {
    return post(
      `/ht/bloodglucose/query?pageSize=${page.pageSize}&pageNum=${page.pageNum}`,
      data
    )
  },
  exportWeightList(data) {
    return exportExl('/ht/weight/exportWeights', data)
  },
  exportBloodList(data) {
    return exportExl(' /ht/bloodpressure/exportBloodPressures', data)
  },
  exportBloodFatList(data) {
    return exportExl('/ht/bloodfat/exportBloodFats', data)
  },
  exportBloodSugerList(data) {
    return exportExl('/ht/bloodglucose/exportBloodGlucoses', data)
  },

  getSuggestList(page) {
    return post(
      `/ht/wechat/feedback/list?pageSize=${page.pageSize}&pageNum=${page.pageNum}`
    )
  },
  getExameAnalysis(data) {
    return post('/ht/management/planAnalyse/analysis', data)
  },
  // 获取直播列表
  getLiveLists(data, page) {
    return get(
      `/ht/livestreaming/list?pageSize=${page.pageSize}&pageNum=${page.pageNum}`,
      data
    )
  },
  // 根据code生成直播二维码
  getLiveCodeImg(liveId) {
    return get('/ht/wxuser/toimages?liveId=' + liveId)
  },
  // 根据直播id获取直播观看人员列表
  getWatchUserLists(data) {
    return post('/ht/live/queryLiveViewDetail', data)
  },
  // 根据性别获取病种
  getDisease(data) {
    return post('/ht/wechat/common/getDisease', data)
  },
  winlinkGet(data) {
    return post('/api/auth/v1/tickets', data)
  },

  // 查询活动列表
  listDiscount(data, page) {
    return post(
      `/ht/wechat/discount/list?pageSize=${page.pageSize}&pageNum=${page.pageNum}`,
      data
    )
  },

  // 查询活动详细
  getDiscount(id) {
    return get(`/ht/wechat/discount/${id}`)
  },

  // 新增活动
  addDiscount(data) {
    return post('/ht/wechat/discount', data)
  },

  // 修改活动
  updateDiscount(data) {
    return update('/ht/wechat/discount', data)
  },

  // 删除活动
  delDiscount(ids) {
    return del(`/ht/wechat/discount/${ids}`)
  },

  // 查询预约列表
  listAppointment(page) {
    return get(
      `/ht/wechat/appointment/list?pageSize=${page.pageSize}&pageNum=${page.pageNum}`,
      page
    )
  },

  // 查询预约详细
  getAppointment(id) {
    return get(`/ht/wechat/appointment/${id}`)
  },

  // 新增预约
  addAppointment(data) {
    return post('/ht/wechat/appointment', data)
  },

  // 修改预约
  updateAppointment(data) {
    return update('/ht/wechat/appointment', data)
  },

  // 删除预约（按照活动id删除）
  delAppointment(ids) {
    return del(`/ht/wechat/appointment/${ids}`)
  },
  // 删除预约记录（按照记录id删除）
  delRAppointment(ids) {
    return del(`/ht/wechat/appointment/del/${ids}`)
  },
  // 删除消息列表
  delReserveMsg(ids) {
    return del(
      `/ht/wechat/reservation_activity/wechat_reservation_activity/${ids}`
    )
  },
  // 增加消息列表
  addReserveMsg(data) {
    return post(
      '/ht/wechat/reservation_activity/wechat_reservation_activity',
      data
    )
  },
  getLiveExport(data) {
    return exportExl('/livestreaming/export', data)
  },
  // 查询常见问题汇总列表
  listQuestion(page, data) {
    return post(
      `/ht/wechat/frequentlyQuestion/question/list?pageSize=${page.pageSize}&pageNum=${page.pageNum}`,
      data
    )
  },

  // 查询常见问题汇总详细
  getQuestion(id) {
    return get(`/ht/wechat/frequentlyQuestion/question/${id}`)
  },

  // 新增常见问题汇总
  addQuestion(data) {
    console.log(data)
    return post('/ht/wechat/frequentlyQuestion/question', data)
  },

  // 修改常见问题汇总
  updateQuestion(data) {
    return update('/ht/wechat/frequentlyQuestion/question', data)
  },

  // 删除常见问题汇总
  delQuestion(id) {
    return del(`/ht/wechat/frequentlyQuestion/question/${id}`)
  },
  // 删除常见问题汇总
  getwxMsg() {
    return post('/ht/wechat/wxMsg')
  },
  communityDlownd(data) {
    return exportExl('/ht/community/export', data)
  },
}
